import React, {forwardRef} from 'react';
import {Link} from "@inertiajs/inertia-react";
import LoaderComponent from "@/Pages/All/LoaderComponent";

const LinkLoad = forwardRef((
    {
        method = 'get',
        as = 'a',
        href,
        data = {},
        active = false,
        className = '',
        children,
        onMouseOver,
        dataSrc,
        onClick = () => {
        },
    },
    ref
) => {
    const [loading, setLoading] = React.useState(false);

    const handleClick = () => {
        if (method === 'get') {
            setLoading(true);
        }
        onClick();
    };

    const handleLoad = () => {
        setLoading(false);
    };

    React.useEffect(() => {
        window.addEventListener("load", handleLoad);
        return () => {
            window.removeEventListener("load", handleLoad);
        };
    }, []);

    return (
        <>
            {loading ? <LoaderComponent /> : null}
            <Link
                method={method}
                as={as}
                data={data}
                href={href}
                ref={ref}
                onClick={handleClick}
                className={`${className}${active ? ' active' : ''}`}
                onMouseOver={onMouseOver}
                data-src={dataSrc}
            >
                {children}
            </Link>
        </>
    );
});

export default LinkLoad;
